import React, { useEffect, useState } from "react";

import { UserAgentApplication } from "msal";
import { getUserDetails } from "./GraphService";
import config from "./Config";

import NavBar from "./components/NavBar";
import NavBarLogin from "./components/NavBarLogin";
import Footer from "./components/Footer";
import { Route, HashRouter, BrowserRouter, Switch } from "react-router-dom";

import CallAPIs from "./components/CallAPIs";
import DetailContainer from "./components/DetailContainer";
import ReportsContainer from "./components/ReportsContainer";
import SearchFilters from "./components/SearchFilters";
import { Container } from "react-bootstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Fragment } from "react";
import FirstPageContent from "./components/FirstPageContent";
import SupportPageContnet from "./components/SupportPageContnet";
import { FunctionsStore } from "./FunctionsStore";
import DashboardContent from "./components/Dashboard";
function App() {
  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });

  const [loginState, setLoginState] = useState({
    isAuthenticated: false,
    user: {},
    error: null,
  });

  useEffect(() => {
    let user = userAgentApplication.getAccount();
    //console.log(user);
    if (user) {
      // Enhance user object with data from Graph
      getUserProfile();
    }
  }, []);

  const login = async () => {
    try {
      await userAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt: "select_account",
      });
      // const apiTenants = await new CallAPIs().GetTenants();

      // await new CallAPIs().PostUserlogging(
      //   apiTenants.data.length > 0 ? apiTenants.data[0].identifier : "",
      //   { LoggingType: FunctionsStore.LoginTypes.LoginWeb }
      // );
      await getUserProfile();
    } catch (err) {
      var error = {};

      if (typeof err === "string") {
        var errParts = err.split("|");
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err),
        };
      }
      // const sssss = await GetCurrentUser();
      // console.log("sssssss", sssss);
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error,
      });
    }
  };

  const logout = () => {
    userAgentApplication.logout();
  };

  const getUserProfile = async () => {
    try {
      //test pipe line2
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      var accessToken = await userAgentApplication.acquireTokenSilent({
        scopes: config.scopes,
      });

      // alert(accessToken.accessToken);
      if (accessToken) {
        // Get the user's profile from Graph
        var user = await getUserDetails(accessToken);
        setLoginState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
            givenName: user.givenName,
            surname: user.surname,
          },
          // token: accessToken.accessToken.toString(),
          error: null,
        });
      }
    } catch (err) {
      var error = {};
      if (typeof err === "string") {
        var errParts = err.split("|");
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err),
        };
      }

      setLoginState({
        isAuthenticated: false,
        user: {},
        // token: "",
        error: error,
      });
    }
  };

  const [currentUser, setCurrentUserState] = useState({
    data: {},
    error: null,
  });
  const GetCurrentUser = async () => {
    const res = await new CallAPIs().GetCurrentUser();
    // console.log(res.data);
    setCurrentUserState({
      data: res.data,
    });
  };

  return (
    <div className="app">
      {loginState.isAuthenticated ? (
        <HashRouter>
          <Route exact path="/">
            <Container>
              <NavBar onLogoutClick={logout} />
              <SearchFilters />
              <Footer />
            </Container>
          </Route>
          <Route path="/Detail" component={DetailContainer}></Route>
          <Route path="/Reports" component={ReportsContainer}></Route>

          <Route path="/Dashboard">
            <NavBar onLogInClick={login} />
            <DashboardContent />
            <Footer />
          </Route>
          <Route path="/Support">
            <NavBar onLogInClick={login} />
            <SupportPageContnet />
            <Footer />
          </Route>
        </HashRouter>
      ) : (
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Container>
                <NavBarLogin onLogInClick={login} />
                <FirstPageContent />
                <Footer />
              </Container>
            </Route>
            <Route path="/Support" component={SupportPageContnet}>
              <Container>
                <NavBarLogin onLogInClick={login} />
                <div
                  style={{ height: "100vh" }}
                  className="d-flex justify-content-center align-items-center "
                >
                  <div className="row">
                    <p className="col shadow p-3 mb-5 bg-white rounded">
                      For any support contact us via: <a>appsupport@apply.no</a>{" "}
                    </p>
                  </div>
                </div>

                <Footer />
              </Container>
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
