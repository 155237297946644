import React, { Component } from "react";
import logo from "../images/logo.svg";
import "./Style.css";
import { UserAgentApplication } from "msal";
import config from "../Config";
import CallAPIs from "./CallAPIs";
import { Container, Row, Col, Navbar, NavItem, Nav } from "react-bootstrap";
import { Fragment } from "react";
import { FunctionsStore } from "../FunctionsStore";
import { Router, withRouter } from "react-router-dom";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      picture: "",
    };
    // this.userAgentApplication = new UserAgentApplication({
    //   auth: {
    //     clientId: config.appId,
    //     redirectUri: config.redirectUri,
    //   },
    //   cache: {
    //     cacheLocation: "localStorage",
    //     storeAuthStateInCookie: true,
    //   },
    // });
    this.goBack = this.goBack.bind(this);
    this.navToSupport = this.navToSupport.bind(this);
    this.navToReports = this.navToReports.bind(this);
    this.navToDashboard = this.navToDashboard.bind(this);
  }
  goBack() {
    if (this.props.history.location.pathname != "/")
      this.props.history.goBack();
  }
  navToSupport() {
    this.props.history.push({
      pathname: "/support",
    });
  }
  navToReports() {
    this.props.history.push({
      pathname: "/reports",
    });
  }

  navToDashboard() {
    this.props.history.push({
      pathname: "/dashboard",
    });
  }

  componentDidMount() {
    const apiTenants = new CallAPIs()
      .GetTenants()
      .then((result) =>
        new CallAPIs().PostUserlogging(
          result.data.length > 0 ? result.data[0].identifier : "",
          { LoggingType: FunctionsStore.LoginTypes.OpenWeb }
        )
      );
    new CallAPIs().GetCurrentUser().then((x) => {
      this.setState({
        userName: x.data.givenName + " " + x.data.familyName,
        picture: x.data.picture,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <Navbar fixed="top" className="bg-custom custom-nav" role="navigation">
          <Navbar.Brand href="/">
            {" "}
            <img src={logo} width="131" height="50" alt="Apply" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Item>
                <Nav.Link bsPrefix="nav-link" onClick={this.goBack}>
                  Home
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link bsPrefix="nav-link" onClick={this.navToReports}>
                  Reports
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link onClick={this.navToDashboard}>Dashboard</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={this.navToSupport}>Support</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={this.props.onLogoutClick}>
                <Nav.Link>Logout</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="disabled" disabled>
                  LoggedIn As : {this.state.userName}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Fragment>
    );
  }
}
export default withRouter(NavBar);
