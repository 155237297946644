import React, { Component, createRef, Fragment } from "react";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { Router, withRouter } from "react-router-dom";
import { FunctionsStore } from "./../FunctionsStore.js";

import Loader from "react-loader-spinner";

class FirstPageContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center "
      >
        <p className="shadow p-3 mb-5 bg-white rounded">
          Velkommen til ADOS Verify. Klikk på innloggingsknappen øverst til
          høyre for å gå videre
        </p>
      </div>
    );
  }
}
export default FirstPageContent;
