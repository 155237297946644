import React, { useState, useEffect } from "react";

import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";

import { InfoCard } from "./InfoCard";

import "./Style.css";
import _ from "lodash";
import { FunctionsStore } from "../FunctionsStore";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CallAPIs from "./CallAPIs";
import Plot from "react-plotly.js";
import { Container, Row, Col } from "react-bootstrap";

export default function DashboardContent() {
  const [dateSelection, setDateSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [allData, setAllData] = useState([
    {
      AllData: [],
    },
  ]);
  const [usingAppRaw, setUsingAppRaw] = useState([
    {
      UsingAppRaw: [],
    },
  ]);
  const [userLogginGroupedByDate, setUserLogginGroupedByDate] = useState({
    UserLogginGroupedByDate: [],
  });
  const [startedProcedures, setStartedProcedures] = useState([
    {
      StartedProcedures: [],
    },
  ]);
  const [userCount, setUserCount] = useState(0);
  const [canceledProcedures, setCanceledProcedures] = useState([
    {
      CanceledProcedures: [],
    },
  ]);
  const [completedProcedures, setCompletedProcedures] = useState([
    {
      CompletedProcedures: [],
    },
  ]);
  const [tenantUserLoggings, setTenantUserLoggings] = useState({
    TenantUserLoggings: [],
  });
  const [mostViewedProcedure, setMostViewedProcedure] = useState({
    MostViewedProcedure: [],
  });

  useEffect(async () => {
    const apiTenants = (await new CallAPIs().GetTenants()).data;
    var data = await new CallAPIs().GetUserlogging(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );
    setTenantUserLoggings(
      data.data.tenantUserLoggings.filter(
        (x) =>
          new Date(x.keyDate) >= dateSelection[0].startDate &&
          new Date(x.keyDate) <= dateSelection[0].endDate.setHours(23)
      )
    );
    setUserLogginGroupedByDate(
      data.data.userLogginGroupedByDate.filter(
        (x) =>
          new Date(x.keyDate) >= dateSelection[0].startDate &&
          new Date(x.keyDate) <= dateSelection[0].endDate.setHours(23)
      )
    );
    setCompletedProcedures(
      data.data.finishedProcedures.filter(
        (x) =>
          new Date(x.keyDate) >= dateSelection[0].startDate &&
          new Date(x.keyDate) <= dateSelection[0].endDate.setHours(23)
      )
    );
    setStartedProcedures(
      data.data.startedProcedures.filter(
        (x) =>
          new Date(x.keyDate) >= dateSelection[0].startDate &&
          new Date(x.keyDate) <= dateSelection[0].endDate.setHours(23)
      )
    );
    setMostViewedProcedure(
      data.data.mostViewedProcedures.filter(
        (x) =>
          new Date(x.keyDate) >= dateSelection[0].startDate &&
          new Date(x.keyDate) <= dateSelection[0].endDate.setHours(23)
      )
    );
  }, [dateSelection]);

  const UniqueUsers = (data) => {
    const uniqueValues = [];
    data.length > 0 &&
      data.map((x) => {
        if (uniqueValues.indexOf(x.userId) === -1) {
          uniqueValues.push(x.userId);
        }
      });
    return uniqueValues.length;
  };
  const TotalUsage = (data) => {
    let res = 0;
    data.length > 0 &&
      data.map((x) => {
        if (x.count) res += x.count;
      });
    return res;
  };
  const handleChange = (item) => {
    setDateSelection([item.selection]);
  };

  const LoginReport = (data, actionType) => {
    const res = _.groupBy(data, "keyDate");

    const convertedToArray = Object.keys(res).map((key) => res[key]);
    return convertedToArray.map((x) => {
      const filtered = x.filter((y) => y.actionType == actionType);
      return filtered.length > 0 ? filtered[0].count : 0; // each day has just 1 record in each actionType
    });
  };
  const ActiveUserReport = (data, actionType) => {
    const res = _.groupBy(data, "keyDate", "userId");

    const convertedToArray = Object.keys(res).map((key) => res[key]);
    return convertedToArray.map((x) => {
      const filtered = x.filter((y) => y.actionType == actionType);
      return filtered.length > 0 ? filtered[0].userCount : 0; // each day has just 1 record in each actionType
    });
  };
  const CompletedProcReport = (data) => {
    const res = _.groupBy(data, "keyDate");

    const convertedToArray = Object.keys(res).map((key) => res[key]);

    return convertedToArray.map((x) => {
      const filtered = x.filter((y) => y.count > 0);
      return filtered.length > 0 ? filtered[0] : 0; // each day has just 1 record in each actionType
    });
  };
  const uniqueLabels = (data) => {
    const res = _.groupBy(data, "keyDate");

    return Object.keys(res)
      .map((key) => res[key])
      .map((x) => x[0].label);
  };

  return (
    <div className="dash-container">
      <div className="dash-infocards">
        <Col md={3} className="dash-card">
          <InfoCard
            name="Unique users"
            value={UniqueUsers(tenantUserLoggings)}
          />
        </Col>
        <Col md={3} className="dash-card">
          <InfoCard
            name="Total usage"
            value={TotalUsage(userLogginGroupedByDate)}
          />
        </Col>
        <Col md={3} className="dash-card">
          <InfoCard
            name="Completed Procedures"
            value={TotalUsage(completedProcedures)}
          />
        </Col>
        <Col md={3} className="dash-card">
          <InfoCard
            name="Started Procedures"
            value={TotalUsage(startedProcedures)}
          />
        </Col>
      </div>

      <div className="dash-sidebar">
        <h3 className={"dash-h3"} style={{ textAlign: "center" }}>
          Select date / range to filter charts and cards
        </h3>
        <DateRangePicker
          // onChange={item => setDateSelection([item.selection])}
          onChange={handleChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={dateSelection}
          direction="vertical"
          weekStartsOn={0}
          className="dateRangePicker"
          editableDateInputs={true}
        />
      </div>
      <Row className="dash-padd">
        <Col md={11} className="dash-chart">
          <Plot
            layout={{
              title: "Daily views",
              yaxis: {
                title: "Number of views",
              },
            }}
            config={{ displaylogo: false }}
            data={[
              {
                type: "line",
                marker: { color: "#BBB193" },
                name: "web",
                x: uniqueLabels(userLogginGroupedByDate),
                y: LoginReport(
                  userLogginGroupedByDate,
                  FunctionsStore.LoginTypes.OpenWeb
                ).map((x) => x),
              },
              {
                type: "line",
                marker: { color: "#00A5BD" },
                name: "app",
                x: uniqueLabels(userLogginGroupedByDate),
                y: LoginReport(
                  userLogginGroupedByDate,
                  FunctionsStore.LoginTypes.OpenApp
                ).map((x) => x),
              },
            ]}
          />
        </Col>
        <Col md={11} className="dash-chart">
          <Plot
            layout={{
              title: "Unique users",
              yaxis: {
                autotick: false,
                tick0: 0,
                dtick: 1,
                title: "Number of users",
              },
            }}
            config={{ displaylogo: false }}
            data={[
              {
                type: "line",
                marker: { color: "#BBB193" },
                name: "web",
                x: uniqueLabels(userLogginGroupedByDate),
                y: ActiveUserReport(
                  userLogginGroupedByDate,
                  FunctionsStore.LoginTypes.OpenWeb
                ).map((x) => x),
              },
              {
                type: "line",
                marker: { color: "#00A5BD" },
                name: "app",
                x: uniqueLabels(userLogginGroupedByDate),
                y: ActiveUserReport(
                  userLogginGroupedByDate,
                  FunctionsStore.LoginTypes.OpenApp
                ).map((x) => x),
              },
            ]}
            type="bar"
          />
        </Col>
        <Col md={11} className="dash-chart">
          <Plot
            layout={{ title: "Procedures" }}
            config={{ displaylogo: false }}
            data={[
              {
                type: "bar",
                marker: { color: "#00A5BD" },
                name: "Completed",
                x: uniqueLabels(completedProcedures.concat(startedProcedures)),
                y: CompletedProcReport(completedProcedures).map((x) => x.count),
              },
              {
                type: "bar",
                marker: { color: "#BBB193" },
                name: "Started",
                x: uniqueLabels(startedProcedures.concat(completedProcedures)),
                y: CompletedProcReport(startedProcedures).map((x) => x.count),
              },
            ]}
            type="bar"
          />
        </Col>
        {/* {mostViewedProcedure.length && ( */}
        <Col md={11} className="dash-chart">
          <Plot
            layout={{ title: "Most viewed Procedures" }}
            config={{ displaylogo: false }}
            data={[
              {
                type: "bar",
                marker: { color: "#00A5BD" },
                name: "Most viewed",
                x:
                  mostViewedProcedure.length &&
                  mostViewedProcedure.map((x) => x.label),
                y:
                  mostViewedProcedure.length &&
                  mostViewedProcedure.map((x) => x.count),
                text:
                  mostViewedProcedure.length &&
                  mostViewedProcedure.map((x) => x.name),
              },
            ]}
            type="bar"
          />
        </Col>
        {/* )} */}
      </Row>
    </div>
  );
}
