import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";

import "jquery/dist/jquery.slim.min.js";
import "popper.js/dist/umd/popper.min.js";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-social/bootstrap-social.css";
import "semantic-ui-css/semantic.min.css";

import App from "./App";

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
