import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import SearchContent from "./SearchContent";

import "react-datepicker/dist/react-datepicker.css";
import CallAPIs from "./CallAPIs";
import { green } from "@material-ui/core/colors";

import ReactShadowScroll from "react-shadow-scroll";
import { FunctionsStore } from "../FunctionsStore";
import { Navbar, Nav } from "react-bootstrap";

class SearchFilters extends Component {
  constructor(props) {
    super(props);
    //this.fillDiv = this.fillDiv.bind(this);
    this.state = {
      startDate: "",
      endDate: "",
      statusList: FunctionsStore.filters.statusIdList
        ? FunctionsStore.filters.statusIdList
        : "1,2,3,4,",
      externalSystem: FunctionsStore.externalSystem,
      externalSystemIds: FunctionsStore.filters.externalSystem,
      categories: [],
      justUser:
        FunctionsStore.filters.userOrAll != undefined
          ? FunctionsStore.filters.userOrAll
          : true,
      full: [],

      /**/
      data: [],
      dataUsing: [],
      procedures: [],
      executions: [],
      users: [],
      currentUser: [],
      currentPage: 1,
      pageSize: 5,
      date: "",
      loading: true,

      searchTextValue: "",
      statusIdList: "",
      categories: this.props.categories,
      currentUserProcedures: [],
      comments: [],
      systemNumber: "Systemnummer",
    };

    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.radioChange = this.radioChange.bind(this);
    this.handleSystemNumberFilter = this.handleSystemNumberFilter.bind(this);
    //this.handleResetButton = this.handleResetButton.bind(this);
  }

  handleSystemNumberFilter(e, x) {
    // {this.state.systemNumber}
    FunctionsStore.date = "";
    if (x == undefined) return;
    FunctionsStore.filters.startupProc = x;
    this.setState({ systemNumber: x.name });

    if (x.procedures.length > 0) {
      const proc = this.state.full.filter((f) =>
        x.procedures.some((p) => p.id === f.procedureId)
      );
      this.child.setState(
        { data: proc, userOrAll: this.state.justUser /*, dataUsing: proc  */ },
        () => {
          this.child.setState({
            dataUsing: this.child.search(
              this.state.startDate,
              this.state.endDate,
              {
                externalSystems: this.state.externalSystemIds,
              }
            ),
          });
        }
      );
    } else {
      alert("There are no procedures in this category");
    }
  }
  handleStartDate(date) {
    if (this.state.endDate && date > this.state.endDate) {
      alert("the start date should not be greater than end date");
      return;
    }
    FunctionsStore.date = "";

    this.setState({
      startDate: date,
    });
    this.child.setState({ startDate: date });

    this.child.search(date, this.state.endDate);
  }
  handleEndDate(date) {
    if (date < this.state.startDate) {
      alert("the end date should not be lower than start date");
      return;
    }
    FunctionsStore.date = "";

    this.setState({
      endDate: date.setDate(date.getDate()),
    });
    this.child.setState({ endDate: date });

    this.child.search(this.state.startDate, date);
  }
  handleActiveCheck(e) {
    FunctionsStore.date = "";
    let statusListLocal = "";
    e.target.checked
      ? (statusListLocal = this.state.statusList + "1,")
      : (statusListLocal = this.state.statusList.replace("1,", ""));
    this.setState({ statusList: statusListLocal });
    this.child.search(this.state.startDate, this.state.endDate, {
      statusId: statusListLocal,
    });
  }
  handleCancelCheck(e) {
    FunctionsStore.date = "";
    let statusListLocal = "";
    e.target.checked
      ? (statusListLocal = this.state.statusList + "2,")
      : (statusListLocal = this.state.statusList.replace("2,", ""));
    this.setState({ statusList: statusListLocal });
    this.child.search(this.state.startDate, this.state.endDate, {
      statusId: statusListLocal,
    });
  }
  handleCompleteCheck(e) {
    FunctionsStore.date = "";

    let statusListLocal = "";
    e.target.checked
      ? (statusListLocal = this.state.statusList + "3,")
      : (statusListLocal = this.state.statusList.replace("3,", ""));
    this.setState({ statusList: statusListLocal });
    this.child.search(this.state.startDate, this.state.endDate, {
      statusId: statusListLocal,
    });
  }

  handleExternalSystems(e, x) {
    FunctionsStore.date = "";
    let externalSystemLocal = "";
    e.target.checked
      ? (externalSystemLocal = this.state.externalSystemIds + x + ",")
      : (externalSystemLocal = this.state.externalSystemIds.replace(
          x + ",",
          ""
        ));
    this.setState({ externalSystemIds: externalSystemLocal });
    FunctionsStore.filters.externalSystem = externalSystemLocal;
    this.child.search(this.state.startDate, this.state.endDate, {
      externalSystems: externalSystemLocal,
    });
  }

  async componentDidMount() {
    this.setState({
      startDate: FunctionsStore.filters.startDate,
      endDate: FunctionsStore.filters.endDate,
    });
    const apiTenants = (await new CallAPIs().GetTenants()).data;

    /*
    ************* 
    Using this function:
    1. use the image picture
    2. filter procefures that are related to this user: when user selsct 'Dine'
    ************* */
    const apiCurrentUser = await new CallAPIs().GetCurrentUser();
    // console.dir("current user", apiCurrentUser.data);
    this.child.setState({ currentUser: apiCurrentUser.data });

    /*
    ************* 
        get USERS 
        Using the `state.users` can find users in the main table, participants 
        in Detail page, and...
    ************* 
    */
    const apiUsers = await new CallAPIs().GetTenantUser(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );
    // console.dir(apiUsers.data);
    this.child.setState({ users: apiUsers.data });

    /*
    ************* 
        get executions of all users and the current user:
        we get the procedureId s of executions that are started by current user
        when we want to filter by user, chech the procedures that are in the list 
        of current user procedureIds
    ************* 
    */
    const apiExecutions = await new CallAPIs().GetExecution(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );

    this.child.setState({ executions: apiExecutions.data });
    let currentUserProce = apiExecutions.data
      .filter((y) => y.startedByUserId == apiCurrentUser.data.userId)
      .map((z) => z.executionId)
      .filter(FunctionsStore.onlyUnique);

    this.child.setState({
      currentUserProcedures: currentUserProce,
    });

    /*
    ************* 
    here we get the procedures and join it to the executions since every procedure has
    multiple executions and we want to show all of them 
    Moreover, we sort them by date
    ************* 
    */
    let apiProcedures = await new CallAPIs().GetProcedures(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );
    FunctionsStore.procedures = apiProcedures.data;
    this.child.setState({ procedures: apiProcedures.data.procedures });

    let full = apiExecutions.data
      .filter((e) => e.isTopLevel) // && e.children.length === 0)
      .map((e) => {
        return {
          ...e,
          ...apiProcedures.data.find((p) => p.id === e.procedureId),
          sortDate: e.endedAt == undefined ? e.startedAt : e.endedAt,
        };
      });

    let parentFull = apiExecutions.data
      //.filter((e) => e.isTopLevel)
      // && e.children.length > 0)
      .map((e) => {
        return {
          ...e,
          ...apiProcedures.data.find((p) => p.id === e.procedureId),
          sortDate: e.endedAt == undefined ? e.startedAt : e.endedAt,
        };
      });
    parentFull.sort((a, b) =>
      //new Date(a.startedAt) < new Date(b.startedAt) ? 1 : -1
      FunctionsStore.sortByTime(
        a.endedAt == undefined ? a.startedAt : a.endedAt,
        b.endedAt == undefined ? b.startedAt : b.endedAt
      )
    );
    // console.log("parentfull", parentFull);
    this.setState({
      full: parentFull,
    });

    full.sort((a, b) => {
      return FunctionsStore.sortByTime(
        a.endedAt == undefined ? a.startedAt : a.endedAt,
        b.endedAt == undefined ? b.startedAt : b.endedAt
      );
    });
    const category = await new CallAPIs().GetCategory(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );
    // console.dir("category", JSON.stringify(category.data));
    this.setState({
      categories: category.data,

      // procedures: apiProcedures.data.procedures,
    });
    // this.props.fillParent(full, category);

    //      full.splice(full.find(f=>f.procedureId==se.)))
    /*
    ************* 
     we use data to stor all data we need in the first page and dataUsing for the filtered 
     data each time a filter applies on data, we updata dataUsing.
     Also, for pagination we use dataUsing.slice to show a specific number of rows
    ************* */
    this.child.setState({ firstData: full, data: full, dataUsing: full });
    // console.dir("number of executions", x.data.executions);

    // const finres = this.join(y.data.procedures, x.data.executions);
    // this.setState({ data: finres, dataUsing: finres });
    // console.dir("final", JSON.stringify(full));
    this.child.filterByUser(
      FunctionsStore.filters.userOrAll != undefined
        ? FunctionsStore.filters.userOrAll
        : true
    );

    // });

    // console.dir("***************", res);
    // this.ffff(x.data.executions, res);
    // });
    /*
    ************* 
     use this comment to send it to the Detail page.
    ************* */

    const apiComment = await new CallAPIs().GetCommentExecution(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );
    // console.dir("comments", apiComment.data);
    this.child.setState({ comments: apiComment.data });
    this.child.setState({ loading: false });
    this.handleSystemNumberFilter("", FunctionsStore.filters.startupProc);

    const apiExternalSystems = await new CallAPIs().GetExternalSystems(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );
    FunctionsStore.externalSystems = apiExternalSystems.data;

    if (!FunctionsStore.filters.externalSystem) {
      FunctionsStore.filters.externalSystem = "0,";
      apiExternalSystems.data.map(
        (es) => (FunctionsStore.filters.externalSystem += es.id + ",")
      );
      this.setState({
        externalSystemIds: FunctionsStore.filters.externalSystem,
      });
    }
    let externalSystemId = "0,";
    apiExternalSystems.data.map((z) => (externalSystemId += z.id + ","));
    this.setState({
      externalSystem: apiExternalSystems.data,
    });
    FunctionsStore.date = "";
    this.child.setState({ externalSystems: externalSystemId });
  }

  radioChange(event) {
    FunctionsStore.date = "";
    if (event.target.value == "dine") {
      FunctionsStore.filters.userOrAll = true;
      this.setState(
        {
          justUser: true,
        },
        () => {
          this.child.filterByUser(true);
        }
      );
    } else {
      FunctionsStore.filters.userOrAll = false;

      this.setState(
        {
          justUser: false,
        },
        () => {
          this.child.filterByUser(false);
        }
      );
    }
  }

  render() {
    return (
      <Fragment>
        <Container className="custom-main">
          {" "}
          <Row>
            <Col md={12}>
              {/* Search Filter Section */}
              <Row>
                <Col
                  md={4}
                  className="section-search-filters padding-top-25px height-100vh"
                >
                  <form>
                    <div className="row mt-3 border-bottom border-10 border-secondary ">
                      <div className="col-6 col-md-6">
                        <p>Søkekriterier</p>
                      </div>
                      <Col md={6}>
                        <a
                          href="/"
                          className="btn btn-light btn-sm mb-3 float-right"
                        >
                          Tilbakestille filtre
                        </a>
                      </Col>
                      <div className="col-12 col-md-12">
                        <div className="dropdown">
                          <button
                            className="btn btn-light btn-sm mb-3 dropdown-toggle "
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {this.state.systemNumber}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {/* <ScrollArea> */}
                            {this.state.categories &&
                              this.state.full.length > 0 &&
                              this.state.categories.map((x) => {
                                return (
                                  <Fragment key={`${x.id} ${x.name}`}>
                                    <option
                                      className="dropdown-item"
                                      value={x.name}
                                      onClick={(e) =>
                                        this.handleSystemNumberFilter(e, x)
                                      }
                                    >
                                      {x.name}
                                    </option>
                                  </Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 border-bottom border-10 border-secondary align-items-center ">
                      <div
                        id="radio-user"
                        className="col-12 col-md-12 btn-group btn-group-toggle mb-3 align-self-center"
                        data-toggle="buttons"
                      >
                        <label
                          className={`btn btn-light btn-md 
                          ${this.state.justUser ? "active" : ""}`}
                        >
                          <input
                            type="radio"
                            name="options"
                            id="option1"
                            value="dine"
                            onChange={this.radioChange}
                          />{" "}
                          Dine
                        </label>
                        <label
                          className={`btn btn-light btn-md 
                              ${!this.state.justUser ? "active" : ""}`}
                        >
                          <input
                            type="radio"
                            name="options"
                            id="option2"
                            value="alle"
                            onChange={this.radioChange}
                          />{" "}
                          Alle
                        </label>
                      </div>
                    </div>
                    <div className="row mt-3 border-bottom border-10 border-secondary align-items-center ">
                      <div className="form-group row ml-3">
                        <label htmlFor="date1">Dato:</label>
                      </div>
                      <div className="form-group row ml-3">
                        <label>Fra:</label>

                        <DatePicker
                          className="datepicker"
                          selected={this.state.startDate}
                          onChange={this.handleStartDate}
                          placeholderText="DD.MM.YYYY"
                          dateFormat="dd.MM.yyyy"
                        />
                        {/* </div> */}
                        <label>Til:</label>

                        <DatePicker
                          className="datepicker"
                          selected={this.state.endDate}
                          onChange={this.handleEndDate}
                          placeholderText="DD.MM.YYYY"
                          dateFormat="dd.MM.yyyy"
                        />
                        {/* </div> */}
                      </div>
                    </div>

                    <div className="row mt-3 border-bottom border-10 border-secondary align-items-center ">
                      <label className="col-md-12 " htmlFor="status">
                        Status:
                      </label>
                      <div className="col-md-12 form-check ml-3" id="status">
                        <label className="form-check-label" htmlFor="check1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={
                              FunctionsStore.filters.statusIdList
                                ? FunctionsStore.filters.statusIdList.includes(
                                    "1"
                                  )
                                : true
                            }
                            onChange={(e) => this.handleActiveCheck(e)}
                          />
                          Aktive
                        </label>
                      </div>
                      <div className="col-md-12 form-check ml-3">
                        <label className="form-check-label" htmlFor="check2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={
                              FunctionsStore.filters.statusIdList
                                ? FunctionsStore.filters.statusIdList.includes(
                                    "3"
                                  )
                                : true
                            }
                            onChange={(e) => this.handleCompleteCheck(e)}
                          />
                          Fullført
                        </label>
                      </div>
                      <div className="col-md-12 form-check ml-3 mb-3">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={
                              FunctionsStore.filters.statusIdList
                                ? FunctionsStore.filters.statusIdList.includes(
                                    "2"
                                  )
                                : true
                            }
                            onChange={(e) => this.handleCancelCheck(e)}
                          />
                          Kansellert
                        </label>
                      </div>
                    </div>
                    <div className="row mt-3 align-items-center ">
                      <label
                        className="col-md-12 " //htmlFor="status"
                      >
                        Eksternt Systems(coming):
                      </label>
                      {this.state.externalSystem &&
                        this.state.externalSystem.map((es) => (
                          <div
                            className="col-md-12 form-check ml-3"
                            key={es.name}
                          >
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                defaultChecked={
                                  this.state.externalSystemIds
                                    ? this.state.externalSystemIds.includes(
                                        es.id + ""
                                      )
                                    : false
                                }
                                onChange={(e) =>
                                  this.handleExternalSystems(e, es.id)
                                }
                              />
                              {es.name}
                            </label>
                          </div>
                        ))}

                      <div className="col-md-12 form-check ml-3">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            key="0All"
                            defaultChecked={
                              this.state.externalSystemIds
                                ? this.state.externalSystemIds.includes("0")
                                : true
                            }
                            onChange={(e) => this.handleExternalSystems(e, 0)}
                          />
                          Alle
                        </label>
                      </div>
                    </div>
                  </form>
                </Col>
                {/* Search Grid Section */}
                <Col md={8}>
                  <SearchContent
                    onRef={(ref) => (this.child = ref)}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default SearchFilters;
