import React, { Component } from "react";

import NavBar from "./NavBar";
import Detail from "./Detail";
import Footer from "./Footer";

// import { withRouter } from 'react-router-dom';

class DetailContainer extends Component {
  componentDidMount(props) {
    // console.log(
    //   "id in detail container componend",
    //   this.props.location && this.props.location.state.data
    // );
  }
  render() {
    return (
      <div className="DetailContainer">
        <NavBar />
        <Detail
          data={this.props.location.state && this.props.location.state.data}
          procedures={
            this.props.location.state && this.props.location.state.procedures
          }
          executions={
            this.props.location.state && this.props.location.state.executions
          }
          userName={
            this.props.location.state && this.props.location.state.userName
          }
          userList={
            this.props.location.state && this.props.location.state.userList
          }
          comments={
            this.props.location.state && this.props.location.state.comments
          }
        />
        <Footer />
      </div>
    );
  }
}
export default DetailContainer;
