import React from "react";

import PropTypes from "prop-types";

const InfoCard = (props) => {
  return (
    <div className={props.className}>
      <h3>{props.name}</h3>
      <h2>{props.value}</h2>
    </div>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
};

export { InfoCard };
