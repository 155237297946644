import { UserAgentApplication } from "msal";
import { Component } from "react";
import config from "../Config";

class AzureToken extends Component {
  constructor(props) {
    super(props);
    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    });
    switch (window.location.hostname) {
      case "localhost":
        this.Base_Url = "https://localhost:5001/";
        break;
      case "verify-dev.apply.no":
        this.Base_Url = "https://verify-dev-api.azurewebsites.net/";
        break;
      default:
        this.Base_Url = "https://verify-api.apply.no/";
        break;
    }
  }

  getToken = async () => {
    // grab current state
    var token = await this.userAgentApplication.acquireTokenSilent({
      scopes: config.scopes1,
    });
    // alert(token.accessToken);
    return {
      headers: {
        //"Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyIsImtpZCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyJ9.eyJhdWQiOiJhcGk6Ly82ZWY0Y2VmNy1mMWJhLTQ0ZjQtYmNiMi1lYzY1OTk2ZGExN2EiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82MDczMzE3OC02MzJjLTRhY2EtODMxNS00ZGE3NzUzNmQxZGUvIiwiaWF0IjoxNjE0OTgyODA3LCJuYmYiOjE2MTQ5ODI4MDcsImV4cCI6MTYxNDk4NjcwNywiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhUQUFBQVl0UTdxa0VPVUR6bmFuYVNabEMvVkpFM2NDR0MwcGRZQW5oeWZQeEFPQlU1anlPV2tkOG1hYSsrd0VYdkdwUm5YempzeG9WYzhDbW5VZWR0VFdKdmowMkgxNFUrUkdwdFAwc05QQkwvVUtnPSIsImFtciI6WyJwd2QiLCJtZmEiXSwiYXBwaWQiOiI2ZWY0Y2VmNy1mMWJhLTQ0ZjQtYmNiMi1lYzY1OTk2ZGExN2EiLCJhcHBpZGFjciI6IjAiLCJlbWFpbCI6Ik1laGRpLlNoZWlraEBhcHBseS5ubyIsImZhbWlseV9uYW1lIjoiU2hlaWtoIiwiZ2l2ZW5fbmFtZSI6Ik1laGRpIiwiaXBhZGRyIjoiODQuMjA4LjIyMy4xMjciLCJuYW1lIjoiU2hlaWtoLCBNZWhkaSIsIm9pZCI6Ijg2OGRmYjFhLTQ3OTQtNDE4NS1hYjM5LTJkNWYxMjBiYjdjMSIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0xNTQyNDAxNC0xNjU2ODM3MTExLTE0NjI5NzE5MjUtMzQ4OTMiLCJyaCI6IjAuQVNBQWVERnpZQ3hqeWtxREZVMm5kVGJSM3ZmTzlHNjY4ZlJFdkxMc1pabHRvWG9nQU44LiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6Im9mUGliTHZyVWVQMDBZV0FLWGFZZGlEU2JMenFYdXZBQUduNFdkeHB1Qm8iLCJ0aWQiOiI2MDczMzE3OC02MzJjLTRhY2EtODMxNS00ZGE3NzUzNmQxZGUiLCJ1bmlxdWVfbmFtZSI6Ik1laGRpLlNoZWlraEBhcHBseS5ubyIsInVwbiI6Ik1laGRpLlNoZWlraEBhcHBseS5ubyIsInV0aSI6InBDNkdCQWkzSGtPTzE1U2J5bzBnQUEiLCJ2ZXIiOiIxLjAifQ.DGdRsv8cQ98d99PdMal0UfTmNMeLWMVht2a-f0OZ8W3KyybjzmkZ3wy7x8NAw8zYSXxWBAWVuAjm5xte2S_QP5CSpAUPrf4WdjgmgX-UPpXJngH1k0ivCWj_E34JSUsSgVRaX7Ui-1wJT7uEIcaSAKdp2CwkTCTV75PWQsslkh0qzDihpmV3gn2aFMiXvqSQAYyNGGmrS_yyvAVpybxTxvR3s11p-t-wuOuX4wAH0Au1x45ZuHFACVvp05EiKHB4nefUhThc6lSv4uAUDDvMvEnyXTt2VbI_FBb53SdVA75YkHoL2FBzQRxjcmYKPHcHWWeo2x1sS7-7QslgPpBJbg`,
        Authorization: `Bearer ${token.accessToken}`, // the token is a variable which holds the token
      },
    };
  };
  ////////////////////
  logout = () => {
    this.userAgentApplication.logout();
  };

  ////////////////////
}
export default AzureToken;
