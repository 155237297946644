import React from "react";
import { Component } from "react";

export class FunctionsStore extends Component {
  static date = "";
  static filters = {
    startDate: "",
    endDate: "",
  };
  constructor(props) {
    super(props);
  }
  static LoginTypes = {
    //LoginApp : 1,
    OpenWeb: 1,
    OpenApp: 2,
    //Openweb:  4,
  };
  /*

  */
  static procedures = [];
  static 7 = [];
  static ChechDateForSpan(dateValue) {
    //console.log(state);
    var months = [
      "Januar",
      "Februar",
      "Mars",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    //var monthName = months[d.getMonth()];
    // console.log(dateValue);
    // console.log(this.state.date);
    const newDate = new Date(dateValue);
    const variable = `${("0" + newDate.getDate()).slice(-2)} ${
      months[newDate.getMonth()]
    } ${newDate.getFullYear()}`;
    // console.log(variable);
    if (variable != FunctionsStore.date) {
      FunctionsStore.date = variable;
      return (
        <tr className="no">
          <td colSpan="7">
            <div className="nodiv">{variable}</div>
          </td>
        </tr>
      );
    }
  }
  /*
  ***********************
  show the date in a format 'dd.MM.YYYY hh:mm
  ***********************
  */
  static formattedDate(date) {
    const newDate = new Date(date);
    return `${("0" + newDate.getDate()).slice(-2)}.${
      newDate.getMonth() + 1
    }.${newDate.getFullYear()} 
    ${("0" + newDate.getHours()).slice(-2)}:${(
      "0" + newDate.getMinutes()
    ).slice(-2)}`;
    // return newDate.get + "," + month + "," + year;
  }
  /*
  ***********************
  this function is used for showing the status of each row in first page
  ***********************
  */
  static checkActivationMode(value) {
    switch (value) {
      case 1:
        return "Aktive";
      case 2:
        return "Kansellert";
      case 3:
        return "Fullført";
    }
  }

  /*
  ***********************
  this function is used for showing the "before" color of each row 
  ***********************
   */
  static checkColor(value) {
    switch (value) {
      case 1:
        return "blue";
      case 2:
        return "gray";
      case 3:
        return "green";
    }
  }

  /*
  ***********************
  this function is to find user name in procedure names
  this function uses startedByUserId
  ***********************
   */
  static FindUser(row, userList, executions) {
    if (row && userList && executions) {
      let user = userList.find((z) => z.userId == row.startedByUserId);
      return [user.givenName + " " + user.familyName, user];
    }
  }
  /*
   */
  static FindUserById(x, userList) {
    let user = userList.find((z) => z.userId.toLowerCase() == x.toLowerCase());
    return [user?.givenName + " " + user?.familyName, user];
  }

  /*
    ************* 
     this method helps us to find uniqe values in joins and selects
     for example, in Detail page it check contributers Ids and then show their names
    ************* */
  static onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  /*
******************
show the picture and name of contributers of the procedures
******************
 */
  static showContributers(userList, data) {
    let executionsUsers = [];
    data.participants.map((y) => {
      executionsUsers = executionsUsers.concat(y.userId);
    });

    return executionsUsers.filter(FunctionsStore.onlyUnique).map((x) => {
      return (
        <p>
          <img src={FunctionsStore.FindUserById(x, userList)[1].picture} />
          {" " + FunctionsStore.FindUserById(x, userList)[0]}
        </p>
      );
    });
  }
  /*
******************
  we can use this function if we want limit the characters of procedure name
******************
  */
  static checkStringLength(value) {
    if (value.length >= 32) return value.substring(0, 32) + "...";
    return value;
  }

  /*
   ***********
   sort by time 
   * **********
  */
  static sortByTime(a, b) {
    return new Date(a) < new Date(b) ? 1 : -1;
  }

  /*
  *************
  show the External system name
  *************
  */
  static externalSystemSwitch(param) {
    return this.externalSystems
      ? this.externalSystems.find((es) => parseInt(es.id) == param)
      : "";
  }
}
