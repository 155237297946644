import React, { Component, Button } from "react";

import Loader from "react-loader-spinner";
// import { Button } from "bootstrap";

class SupportPageContnet extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center "
      >
        <div className="row">
          <p className="col col-sm-12  shadow p-3  bg-white rounded">
            For kundesupport ta kontakt med:
            <a href="mailto:appsupport@apply.no"> appsupport@apply.no</a> <br />
            last ned brukermanual{" "}
            <a
              style={{ fontSize: "1rem", backgroundColor: "#c1cdda" }}
              className="btn btn-light btn-sm "
              download
              href="/Brukermanual-Verify.pptx"
            >
              {" "}
              nedlasting
            </a>
          </p>

          {/* <Button>
            last ned brukermanua{" "}
           
              this link
            </a>
          </Button> */}
        </div>
      </div>
    );
  }
}
export default SupportPageContnet;
