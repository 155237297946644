import React, { Component } from "react";

import NavBar from "./NavBar";
import Statistics from "./Statistic";
import Footer from "./Footer";
import CallAPIs from "./CallAPIs";

class ReportsContainer extends Component {
  componentDidMount(props) {}

  render() {
    return (
      <div className="ReportsContainer">
        <NavBar />
        <div className="custom-main">
          <Statistics />
        </div>
        <Footer />
      </div>
    );
  }
}
export default ReportsContainer;
