import "./Style.css";
import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Router, withRouter, HashRouter, Link, Route } from "react-router-dom";
import DatePicker from "react-datepicker";
import CallAPIs from "./CallAPIs";
import "react-datepicker/dist/react-datepicker.css";

import { Bar, Pie, Doughnut, Line } from "react-chartjs-2";
import { FunctionsStore } from "../FunctionsStore";
import _ from "lodash";
import ReportsContainer from "./ReportsContainer";

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date().setMonth(new Date().getMonth() - 1),
      endDate: new Date(),
      Alldata: [],
      UsingAppRaw: [],
      UserLogginGroupedByDate: [],
      StartedProcedures: [],
      CanceledProcedures: [],
      CompletedProcedures: [],
      FinishedProcedures: [],
    };
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.ResetFilters = this.ResetFilters.bind(this);
  }
  handleStartDate(date) {
    if (this.state.endDate && date > new Date(this.state.endDate)) {
      alert("the start date should not be greater than end date");
      return;
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    this.setState({
      startDate: date,
    });
    this.LoggingData();
  }
  handleEndDate(date) {
    if (date < this.state.startDate) {
      alert("the end date should not be lower than start date");
      return;
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    this.setState({
      endDate: date,
    });
    this.LoggingData();
  }
  async LoggingData() {
    const apiTenants = (await new CallAPIs().GetTenants()).data;
    var data = await new CallAPIs().GetUserlogging(
      apiTenants.length > 0 ? apiTenants[0].identifier : ""
    );

    data.data.userLogginGroupedByDate =
      data.data.userLogginGroupedByDate.filter(
        (x) =>
          new Date(x.keyDate) >= new Date(this.state.startDate) &&
          new Date(x.keyDate) <= this.state.endDate.setHours(23)
      );
    data.data.startedProcedures = data.data.startedProcedures.filter(
      (x) =>
        new Date(x.keyDate) >= new Date(this.state.startDate) &&
        new Date(x.keyDate) <= this.state.endDate.setHours(23)
    );
    data.data.FinishedProcedures = data.data.finishedProcedures.filter(
      (x) =>
        new Date(x.keyDate) >= new Date(this.state.startDate) &&
        new Date(x.keyDate) <= this.state.endDate.setHours(23)
    );
    this.setState({
      Alldata: data.data,
      UsingAppRaw: data.data.tenantUserLoggings,
      UserLogginGroupedByDate: data.data.userLogginGroupedByDate,
      StartedProcedures: data.data.startedProcedures,
      FinishedProcedures: data.data.FinishedProcedures,
    });
  }
  componentDidMount(props) {
    this.LoggingData();
  }
  randomcolor() {
    const colors = [];
    let i = 0;
    for (i = 0; i < 22; i++) {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      colors.push("rgba(" + r + "," + g + "," + b + ",1)");
    }
    return colors;
  }
  uniqueLabels(data) {
    const res = _.groupBy(data, "keyDate");

    return Object.keys(res)
      .map((key) => res[key])
      .map((x) => x[0].label);
  }
  uniqueProcedureLabels(data1, data2) {
    const res1 = _.groupBy(data1, "keyDate");
    const res2 = _.groupBy(data2, "keyDate");

    const labels = Object.keys(res1)
      .map((key) => res1[key])
      .map((x) => x[0].label);
    Object.keys(res2)
      .map((key) => res2[key])
      .map((x) => labels.push(x[0].label));

    return this.uniqueProcedureLabels(labels);
  }
  unionProcedureCounts(data) {
    const allLabels = this.uniqueProcedureLabels(
      this.state.StartedProcedures,
      this.state.FinishedProcedures
    );
    const finalResult = [];
    allLabels.map((x) => {
      const findRes = data.find((y) => y.label === x);
      findRes != undefined
        ? finalResult.push(findRes.count)
        : finalResult.push(0);
    });
    return finalResult;
  }
  uniqueLabels(data) {
    const uniqueValues = [];
    data.map((x) => {
      if (uniqueValues.indexOf(x.label) === -1) {
        uniqueValues.push(x.label);
      }
    });
    return uniqueValues;
  }
  uniqueProcedureLabels(data) {
    const uniqueValues = [];
    data.map((x) => {
      if (uniqueValues.indexOf(x.label) === -1) {
        uniqueValues.push(x.label);
      }
    });
    return uniqueValues;
  }
  uniqueAppType(data) {
    const uniqueValues = [];
    data.map((x) => {
      if (uniqueValues.indexOf(x.actionType) === -1) {
        uniqueValues.push(x.actionType);
      }
    });
    return uniqueValues;
  }
  LoginReport(data, actionType) {
    const res = _.groupBy(data, "keyDate");
    const convertedToArray = Object.keys(res).map((key) => res[key]);
    //.map((x) => x[0].label);
    return convertedToArray.map((x) => {
      const filtered = x.filter((y) => y.actionType == actionType);
      return filtered.length > 0 ? filtered[0] : 0; // each day has just 1 record in each actionType
    });
  }
  StartedProcedureReport(data) {
    const res = _.groupBy(data, "keyDate");
    const convertedToArray = Object.keys(res).map((key) => res[key]);

    //.map((x) => x[0].label);
    return convertedToArray.map((x) => {
      return x;
    });
  }
  ResetFilters() {
    this.setState({
      startDate: new Date().setMonth(new Date().getMonth() - 1),
      endDate: new Date(),
    });
    this.LoggingData();
  }
  render() {
    return (
      <Fragment>
        <Container className="custom-main">
          {/* Search Filter Section */}
          <Row>
            <Col md={12}>
              <Row>
                <Col
                  md={4}
                  className="section-search-filters padding-top-25px height-220vh"
                >
                  <form>
                    <div className="row mt-3 border-bottom border-10 border-secondary ">
                      <div className="col-6 col-md-6">
                        <p>Søkekriterier</p>
                      </div>
                      <Col md={6}>
                        <div
                          className="btn btn-light btn-sm mb-3 float-right"
                          onClick={this.ResetFilters}
                        >
                          Tilbakestille filtre
                        </div>
                      </Col>
                    </div>

                    <div className="row mt-3 border-bottom border-10 border-secondary align-items-center ">
                      <div className="form-group row ml-3">
                        <label htmlFor="date1">Dato:</label>
                      </div>
                      <div className="form-group row ml-3">
                        <label>Fra:</label>

                        <DatePicker
                          className="datepicker"
                          selected={this.state.startDate}
                          onChange={this.handleStartDate}
                          placeholderText="DD.MM.YYYY"
                          dateFormat="dd.MM.yyyy"
                        />
                        <label>Til:</label>

                        <DatePicker
                          className="datepicker"
                          selected={this.state.endDate}
                          onChange={this.handleEndDate}
                          placeholderText="DD.MM.YYYY"
                          dateFormat="dd.MM.yyyy"
                        />
                      </div>
                    </div>
                  </form>
                </Col>
                {/* Search Grid Section */}
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <Bar
                        data={{
                          labels: this.uniqueLabels(
                            this.state.UserLogginGroupedByDate
                          ),
                          datasets: [
                            {
                              label: ["#Web"],
                              data: this.LoginReport(
                                this.state.UserLogginGroupedByDate,
                                FunctionsStore.LoginTypes.OpenWeb
                              ).map((x) => x.userCount),

                              backgroundColor: ["#00A5BD"], //this.randomcolor(),
                              borderColor: ["#00A5BD"],
                              borderWidth: 1,
                            },
                            {
                              label: ["#App"],
                              data: this.LoginReport(
                                this.state.UserLogginGroupedByDate,
                                FunctionsStore.LoginTypes.OpenApp
                              ).map((x) => x.userCount),

                              backgroundColor: ["#BBB193"],
                              borderColor: ["#BBB193"],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        // height={100}
                        // width={150}
                        options={{
                          responsive: true,
                          plugins: {
                            title: {
                              display: true,
                              text: "Number of users",
                              position: "bottom",
                            },
                            legend: {
                              display: true,
                              align: "center",
                              position: "right",
                            },
                          },
                          maintainAspectRatio: true,
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <Bar
                        data={{
                          labels:
                            this.state.UserLogginGroupedByDate &&
                            this.uniqueLabels(
                              this.state.UserLogginGroupedByDate
                            ),
                          datasets: [
                            {
                              label: ["#Web"],
                              data:
                                this.state.UserLogginGroupedByDate &&
                                this.LoginReport(
                                  this.state.UserLogginGroupedByDate,
                                  FunctionsStore.LoginTypes.OpenWeb
                                ).map((x) => x.count),

                              backgroundColor: ["#00A5BD"], //this.randomcolor(),
                              borderColor: ["#00A5BD"],
                              borderWidth: 1,
                            },
                            {
                              label: ["#App"],
                              data: this.LoginReport(
                                this.state.UserLogginGroupedByDate,
                                FunctionsStore.LoginTypes.OpenApp
                              ).map((x) => x.count),

                              backgroundColor: ["#BBB193"],
                              borderColor: ["#BBB193"],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        // height={100}
                        // width={150}
                        options={{
                          // responsive: true,
                          plugins: {
                            title: {
                              display: true,
                              text: "Number of transactions",
                              position: "bottom",
                            },
                            legend: {
                              display: true,
                              align: "center",
                              position: "right",
                            },
                          },

                          maintainAspectRatio: true,
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <Bar
                        data={{
                          labels: this.uniqueProcedureLabels(
                            this.state.StartedProcedures,
                            this.state.FinishedProcedures
                          ),
                          datasets: [
                            {
                              label: ["#Started"],
                              data: this.unionProcedureCounts(
                                this.state.StartedProcedures
                              ),
                              backgroundColor: ["#00A5BD"], //this.randomcolor(),
                              borderColor: ["#00A5BD"],
                              borderWidth: 1,
                            },

                            {
                              label: ["#Finished"],
                              data: this.unionProcedureCounts(
                                this.state.FinishedProcedures
                              ),

                              backgroundColor: ["#BBB193"],
                              borderColor: ["#BBB193"],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        height={120}
                        // width={150}
                        options={{
                          responsive: true,
                          plugins: {
                            title: {
                              display: true,
                              text: "Number of procedures",
                              position: "bottom",
                            },
                            legend: {
                              display: true,
                              align: "center",
                              position: "right",
                            },
                          },
                          maintainAspectRatio: true,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default withRouter(Statistics);
