import "./Style.css";
import React, { Component } from "react";
import { Router, withRouter } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import CallAPIs from "./CallAPIs";

import Modal from "react-modal";
import { FunctionsStore } from "../FunctionsStore"; //
Modal.setAppElement("#root");

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      informationdivclass: "",
      informationButton: "fa fa-chevron-down btn float-right",
      participatingButton: "fa fa-chevron-down btn float-right",
      participatingdivclass: "",
      isOpen: false,
      commentStep: 1,
      Props1: [],
      loaded: false,
    };
    this.apiTenant1 = "";
    this.apiComment1 = "";
    this.executions = "";
    this.procedures = "";
    this.localprop = {};

    if (this.props.data == undefined || window.performance) {
      if (this.props.data == undefined || performance.navigation.type == 1) {
        new CallAPIs().GetTenants().then((result) => {
          this.apiTenant1 = result.data;

          /////////////////////////
          new CallAPIs()
            .GetExternalSystems(
              this.apiTenant1.length > 0 ? this.apiTenant1[0].identifier : ""
            )
            .then((exResult) => {
              FunctionsStore.externalSystems = exResult.data;

              new CallAPIs()
                .GetCommentExecution(
                  this.apiTenant1.length > 0
                    ? this.apiTenant1[0].identifier
                    : ""
                )
                .then((result2) => {
                  this.localprop.comments = result2.data;
                });
              /////////////
              new CallAPIs()
                .GetExecution(
                  this.apiTenant1.length > 0
                    ? this.apiTenant1[0].identifier
                    : ""
                )
                .then((exec) => {
                  this.localprop.data = exec.data.filter(
                    (e) =>
                      e.executionId ==
                      new URLSearchParams(this.props.location.search).get(
                        "exeId"
                      )
                  );
                  /////////////
                  new CallAPIs()
                    .GetProcedures(
                      this.apiTenant1.length > 0
                        ? this.apiTenant1[0].identifier
                        : ""
                    )
                    .then((pr) => {
                      this.procedures = pr.data.filter(
                        (j) => j.id == this.localprop.data[0].procedureId
                        //new URLSearchParams(this.props.location.search).get("prId")
                      );
                      let full = this.localprop.data.map((e) => {
                        return {
                          ...e,
                          ...this.procedures.find((p) => p.id == e.procedureId),
                        };
                      })[0];
                      this.localprop.data = full;

                      /////////////
                      new CallAPIs()
                        .GetTenantUser(
                          this.apiTenant1.length > 0
                            ? this.apiTenant1[0].identifier
                            : ""
                        )
                        .then((usr) => {
                          this.localprop.userList = usr.data;
                          this.setState({ loaded: true });
                        });
                    });
                });
            });
        });
      } else {
        // window.location.reload();
        // alert("dddd");
        this.setState({ loaded: true });
        this.localprop = this.props;
        // alert("This page is not reloaded");
      }
    }
    this.informationdivclicked = this.informationdivclicked.bind(this);
    this.participatingdivclicked = this.participatingdivclicked.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  toggleModal(e, x) {
    if (x) {
      this.setState({
        isOpen: !this.state.isOpen,
        commentStep: x,
      });
    } else {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  informationdivclicked() {
    if (this.state.informationdivclass === "") {
      this.setState({
        informationdivclass: "coolclass",
        informationButton: "fa fa-chevron-up btn float-right",
      });
    } else {
      this.setState({
        informationdivclass: "",
        informationButton: "fa fa-chevron-down btn float-right",
      });
    }
  }
  participatingdivclicked() {
    if (this.state.participatingdivclass === "") {
      this.setState({
        participatingdivclass: "coolclass",
        participatingButton: "fa fa-chevron-up btn float-right",
      });
    } else {
      this.setState({
        participatingdivclass: "",
        participatingButton: "fa fa-chevron-down btn float-right",
      });
    }
  }

  componentDidMount(props) {
    // console.log(
    //   "this.localprop.data.revisions.",
    //   this.localprop.data.revisions[this.localprop.data.revisions.length - 1]
    //     .revisionNo
    // );
    if (window.performance) {
      if (performance.navigation.type == 1) {
        //alert("from did mount");
      } else {
        this.setState({ loaded: true });
      }
    }
  }
  render() {
    return (
      this.state.loaded && (
        <div className="Detail custom-main">
          <div className="container ">
            <div className="row  d-flex justify-content-center">
              <div className="col col-sm-12 ml-1 mr-1">
                <div className="justify-content-center row-content">
                  <div className="shadow w-90 mb-3 bg-white detail-rounded ">
                    <div
                      className={
                        "p-3 detailblock " +
                        FunctionsStore.checkColor(this.localprop.data.statusId)
                      }
                    >
                      <Modal
                        isOpen={this.state.isOpen}
                        onRequestClose={(e) => this.toggleModal(e)}
                        contentLabel="comment dialog"
                        className="comment-modal"
                        overlayClassName="comment-overlay"
                        closeTimeoutMS={500}
                      >
                        <div>
                          {this.localprop.comments.comments
                            .filter(
                              (y) =>
                                y.executionId ==
                                  this.localprop.data.executionId &&
                                y.step == this.state.commentStep
                            )
                            .map((z) => {
                              return (
                                <div
                                  key={z.commentId}
                                  className="shadow w-85 p-3 mb-5 bg-white rounded "
                                >
                                  {this.localprop.comments.comments
                                    .filter(
                                      (y) =>
                                        y.executionId ==
                                          this.localprop.data.executionId &&
                                        //y.step == this.state.commentStep &&
                                        y.commentId == z.commentId
                                    )
                                    .map((c) =>
                                      this.localprop.data.externalSystems
                                        .filter(
                                          (es) => es.commentId == c.commentId
                                        )
                                        .map((f) => (
                                          <p>
                                            External system:{" "}
                                            <b>
                                              {" " +
                                                FunctionsStore.externalSystemSwitch(
                                                  f.systemId
                                                ).name}
                                            </b>
                                          </p>
                                        ))
                                    )}

                                  <p className=" text-left">
                                    Commented by:
                                    {" " +
                                      FunctionsStore.FindUserById(
                                        z.commentedByUserId,
                                        this.localprop.userList
                                      )[0]}
                                  </p>
                                  <p className=" text-left border-bottom">
                                    Comment:
                                    <ReactMarkdown
                                      children={z.comment}
                                    ></ReactMarkdown>
                                  </p>
                                </div>
                              );
                            })}

                          {this.localprop.data.media.filter(
                            (m) => m.step == this.state.commentStep
                          ).length > 0
                            ? this.localprop.data.media
                                .filter((m) => m.step == this.state.commentStep)
                                .map((media) => (
                                  <div className="shadow w-85 p-3 mb-5 bg-white rounded ">
                                    <p className=" text-left">
                                      Commented by:
                                      {" " +
                                        FunctionsStore.FindUserById(
                                          media.uploadedByUserId,
                                          this.localprop.userList
                                        )[0]}
                                    </p>

                                    <p>
                                      <img
                                        className="w-50 p-3 rounded mx-auto d-block img-fluid"
                                        src={media.uri}
                                      />
                                    </p>
                                  </div>
                                ))
                            : ""}
                        </div>
                        <button onClick={this.toggleModal}>Close</button>
                      </Modal>

                      <label className="detail-label text-left">
                        PROSEDYRER NAVN
                      </label>
                      <p className=" text-left">{this.localprop.data.name}</p>
                      <div className="row">
                        <label className="col col-sm-3 detail-label">
                          REV{" "}
                        </label>
                        <label className="col col-sm-3 detail-label">
                          START DATO{" "}
                        </label>
                        <label className="col col-sm-3 detail-label">
                          STARTET AV{" "}
                        </label>
                      </div>

                      <div className="row">
                        <p className="col col-sm-3 ">
                          {
                            this.localprop.data.revisions[
                              this.localprop.data.revisions.length - 1
                            ].revisionNo
                          }
                          {/* {this.localprop.data.revisions.map((x) => (
                            <span>
                              {x.revisionNo}
                              {this.localprop.data.revisions.length > 1
                                ? ";"
                                : ""}
                            </span>
                          ))} */}
                        </p>
                        <p className="col col-sm-3 ">
                          {FunctionsStore.formattedDate(
                            this.localprop.data.startedAt
                          )}
                        </p>
                        <p className="col col-sm-3 ">
                          {this.localprop.userName}{" "}
                        </p>
                      </div>

                      <div className="row">
                        <label className="col col-sm-3 detail-label">
                          STATUS
                        </label>
                        {this.localprop.data.endedAt && (
                          <label className="col col-sm-3 detail-label">
                            ENDET DATO{" "}
                          </label>
                        )}

                        {this.localprop.data.endedByUserId && (
                          <label className="col col-sm-3 detail-label">
                            ENDET AV{" "}
                          </label>
                        )}
                        <label className="col col-sm-3 detail-label">
                          STEG{" "}
                        </label>
                      </div>
                      <div className="row">
                        <p className="col col-sm-3 ">
                          {FunctionsStore.checkActivationMode(
                            this.localprop.data.statusId
                          )}
                        </p>
                        {this.localprop.data.endedAt && (
                          <p className="col col-sm-3 ">
                            {FunctionsStore.formattedDate(
                              this.localprop.data.endedAt
                            )}
                          </p>
                        )}

                        {this.localprop.data.endedByUserId && (
                          <p className="col col-sm-3 ">
                            {
                              FunctionsStore.FindUserById(
                                this.localprop.data.endedByUserId,
                                this.localprop.userList
                              )[0]
                            }
                          </p>
                        )}
                        <p className="col col-sm-3 ">
                          {
                            this.localprop.data.steps.filter(
                              (s) => s.status == 2
                            ).length
                          }{" "}
                          /{this.localprop.data.revisions[0].steps.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white detail-rounded">
                    <div className="shadow w-90 mb-2 bg-white detail-rounded">
                      <div className=" p-3 dropdown-block">
                        <span className=" pr-3 fa fa-users "> </span>
                        <span className="   ">Deltagende </span>
                        <span
                          className={this.state.participatingButton}
                          onClick={this.participatingdivclicked}
                        ></span>
                      </div>
                      <div
                        id="seconddiv"
                        className={this.state.participatingdivclass}
                      >
                        <div className="col col-sm-3 ">
                          {FunctionsStore.showContributers(
                            this.localprop.userList,
                            this.localprop.data
                          ).map((y) => y)}
                        </div>
                      </div>
                    </div>

                    <div
                      className=" p-3 dropdown-block"
                      style={{ marginBottom: 10 }}
                    >
                      <span className=" pr-3 fa fa-info-circle "> </span>
                      <span className=" ">Informasjon </span>
                      <span
                        className={this.state.informationButton}
                        onClick={this.informationdivclicked}
                      ></span>
                    </div>
                    <div
                      id="seconddiv"
                      className={this.state.informationdivclass}
                    >
                      {
                        <p className="shadow w-85 p-3 mb-5 bg-white rounded ">
                          <ReactMarkdown
                            children={
                              this.localprop.data.revisions[0].description
                            }
                          ></ReactMarkdown>
                        </p>
                      }
                    </div>

                    {this.localprop.data.revisions[
                      this.localprop.data.revisions.length - 1
                    ].steps.map((x, idx) => {
                      const stepNo = idx + 1;
                      let externalsys = "";
                      const step = this.localprop.data.steps.find(
                        (s) => s.step === stepNo
                      );
                      const mediaCount = this.localprop.data.media.filter(
                        (m) => m.step == stepNo
                      ).length;
                      const commentCount = (step?.comments ?? 0) + mediaCount;
                      return (
                        <div
                          className={
                            x.type == "step" ||
                            this.localprop.data.id ==
                              "4279270e-340c-4969-aae4-caad190f67e5"
                              ? "shadow w-85 p-3 mb-5 bg-white rounded"
                              : "shadow w-85 p-3 mb-5 dropdown-block rounded"
                          }
                          onClick={(e) => this.toggleModal(e, stepNo)}
                        >
                          <label
                            className={
                              // x.type == "step"
                              // ?
                              "detail-label text-left"
                              // : "detail-label-info text-left"
                            }
                          >
                            STEG {stepNo}
                            {this.localprop.comments.comments
                              .filter(
                                (y) =>
                                  y.executionId ==
                                    this.localprop.data.executionId &&
                                  y.step == stepNo
                              )
                              .map((c) =>
                                this.localprop.data.externalSystems
                                  .filter((es) => es.commentId == c.commentId)
                                  .map((f) => (
                                    <span key={f.id}>
                                      {" " +
                                        FunctionsStore.externalSystemSwitch(
                                          f.systemId
                                        ).name +
                                        ", "}
                                    </span>
                                  ))
                              )}
                          </label>
                          {x.type == "step" && (
                            <input
                              type="checkbox"
                              disabled
                              className="float-right ml-3 step-checkbox"
                              checked={this.localprop.data.steps.find(
                                (st) => st.step == stepNo && st.status == 2
                              )}
                            />
                          )}
                          <div className="row">
                            <p
                              className={
                                // x.type == "step"?
                                "col col-sm-11 text-left border-bottom"
                                // : "col col-sm-11 text-light text-left border-bottom"
                              }
                            >
                              <ReactMarkdown
                                children={x.description}
                              ></ReactMarkdown>
                            </p>
                          </div>

                          <div className="row"></div>
                          {(x.type == "step" ||
                            this.localprop.data.id ==
                              "4279270e-340c-4969-aae4-caad190f67e5") && (
                            <label
                              className={
                                x.type == "step"
                                  ? "fa fa-comment detail-label"
                                  : "fa fa-comment detail-label-info"
                              }
                            >
                              {commentCount} Kommentarer
                            </label>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}
export default withRouter(Detail);
