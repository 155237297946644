import React, { Component, Fragment } from "react";
import { Container } from "react-bootstrap";
class Footer extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <footer className="footer fixed-bottom">
            <p>@Product by: Moreld Apply 2021</p>
          </footer>
        </Container>
      </Fragment>
    );
  }
}
export default Footer;
