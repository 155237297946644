import { Component } from "react";
import AzureToken from "./AzureToken";
import axios from "axios";

class CallAPIs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Azure: new AzureToken(),
      Endpoints: {
        GET_TENANTS: "api/tenants/",
        GET_PROCEDURES: "/procedures",
        GET_EXECUTIONS: "/executions",
        // GET_STARTUP_EXECUTIONS: "/startup-execution",
        // GET_STARTUP_PROCEDURES: "/startup-procedure",
        GET_CATEGORY: "/categories",
        GET_CURRENT_USER: "api/users/me",
        GET_TENANT_USER: "/users",
        GET_COMMENT: "/executions/comments",
        GET_EXTERNALSYSTEMS: "/external-systems",
        GET_USERLOGGING: "/userlogging",
        POST_USERLOGGING: "/userlogging",
      },
    };
  }
  //#region GET Endpoints
  get = async (path, params = {}) => {
    const item = await this.state.Azure.getToken();
    //console.log(item);
    return await axios.get(`${this.state.Azure.Base_Url + path}`, item, params);
  };

  GetTenants = async () => {
    return await this.get(this.state.Endpoints.GET_TENANTS);
  };

  GetProcedures = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS + x + this.state.Endpoints.GET_PROCEDURES
    );
  };
  // GetStarupProcedures = async (x) => {
  //   return await this.get(
  //     this.state.Endpoints.GET_TENANTS +
  //       x +
  //       this.state.Endpoints.GET_STARTUP_PROCEDURES
  //   );
  // };
  GetExecution = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS + x + this.state.Endpoints.GET_EXECUTIONS
    );
  };
  // GetStarupExecution = async (x) => {
  //   return await this.get(
  //     this.state.Endpoints.GET_TENANTS +
  //       x +
  //       this.state.Endpoints.GET_STARTUP_EXECUTIONS
  //   );
  // };
  GetCommentExecution = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS + x + this.state.Endpoints.GET_COMMENT
    );
  };
  GetCategory = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS + x + this.state.Endpoints.GET_CATEGORY
    );
  };
  GetCurrentUser = async () => {
    return await this.get(this.state.Endpoints.GET_CURRENT_USER);
  };
  GetTenantUser = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS +
        x +
        this.state.Endpoints.GET_TENANT_USER
    );
  };
  GetUserlogging = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS +
        x +
        this.state.Endpoints.GET_USERLOGGING
    );
  };
  GetExternalSystems = async (x) => {
    return await this.get(
      this.state.Endpoints.GET_TENANTS +
        x +
        this.state.Endpoints.GET_EXTERNALSYSTEMS
    );
  };
  //#endregion
  //#region POST

  post = async (path, data) => {
    const item = await this.state.Azure.getToken();
    return await axios.post(`${this.state.Azure.Base_Url + path}`, data, item);
  };
  PostUserlogging = async (x, data) => {
    return await this.post(
      this.state.Endpoints.GET_TENANTS +
        x +
        this.state.Endpoints.POST_USERLOGGING,
      data
    );
  };
  //#endregion
}
export default CallAPIs;
