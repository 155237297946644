module.exports = {
  appId: "6ef4cef7-f1ba-44f4-bcb2-ec65996da17a",
  // redirectUri: "http://localhost:3000/",
  // redirectUri: "https://verifywebtest.azurewebsites.net/",
  redirectUri: `${
    window.location.hostname === "localhost" ? "http" : "https"
  }://${window.location.host}`,
  scopes: [
    // "api://6ef4cef7-f1ba-44f4-bcb2-ec65996da17a/user_impersonation",
    //"offline_access",
    "openid",
    "user.read",
  ],
  scopes1: [
    "api://6ef4cef7-f1ba-44f4-bcb2-ec65996da17a/user_impersonation",
    //"offline_access",
  ],
};
