import React, { Component, createRef, Fragment } from "react";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { FunctionsStore } from "./../FunctionsStore.js";

import Loader from "react-loader-spinner";

class SearchContent extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      firstData: [],
      data: [],
      dataUsing: [],
      procedures: [],
      executions: [],
      users: [],
      currentUser: [],
      currentPage: 1,
      pageSize: 5,
      date: "",
      loading: true,
      startDate: FunctionsStore.filters.startDate, //this.props.startDate,
      endDate: FunctionsStore.filters.endDate, //this.props.endDate,
      userOrAll: this.props.justUser,
      searchTextValue: "",
      statusIdList: "",
      categories: [],
      currentUserProcedures: [],
      comments: [],
      externalSystems: FunctionsStore.filters.externalSystem
        ? FunctionsStore.filters.externalSystem
        : "",
    };
    this.search = this.search.bind(this);
  }
  nextPage() {
    FunctionsStore.date = "";
    if (
      this.state.currentPage <
      Math.ceil(this.state.dataUsing.length / this.state.pageSize)
    )
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
  }
  previousPage() {
    FunctionsStore.date = "";
    if (this.state.currentPage > 1)
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
  }
  naviGateToDetail(value) {
    FunctionsStore.filters = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      searchText: this.state.searchTextValue,
      statusIdList: this.state.statusIdList,
      userOrAll: this.state.userOrAll,
      startupProc: FunctionsStore.filters.startupProc,
      externalSystem: this.state.externalSystems,
    };
    this.props.history.push({
      pathname: "/Detail",
      search: "?" + new URLSearchParams({ exeId: value.executionId }),
      state: {
        data: value,
        // executions: this.state.executions.filter(
        //   (x) => x.procedureId == value.id
        // ),
        //procedures: value,
        userName: FunctionsStore.FindUserById(
          value.startedByUserId,
          this.state.users
        )[0],
        userList: this.state.users,
        comments: this.state.comments,
      },
    });
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  async componentDidMount() {
    if (FunctionsStore.filters.length > 0)
      this.setState({
        startDate: FunctionsStore.filters.startDate,
        endDate: FunctionsStore.filters.endDate,
      });

    this.setState({
      dataUsing: this.search(
        FunctionsStore.filters.startDate,
        FunctionsStore.filters.endDate,
        {
          text: FunctionsStore.filters.searchText,
          statusId: FunctionsStore.filters.statusIdList,
          externalSystems: FunctionsStore.filters.externalSystem,
        }
      ),
    });

    this.props.onRef(this);
  }

  filterByUser(value) {
    if (value) {
      this.setState(
        {
          userOrAll: true,
        },
        () =>
          this.setState({
            dataUsing: this.search(this.state.startDate, this.state.endDate),
            // .filter((x) =>          this.state.currentUserProcedures.includes(x.executionId)),
          })
      );
    } else {
      this.setState(
        {
          userOrAll: false,
        },
        () =>
          this.setState({
            dataUsing: this.search(this.state.startDate, this.state.endDate),
            // .filter((x) =>          this.state.currentUserProcedures.includes(x.executionId)),
          })
      );
    }
  }
  renderTableData() {
    const startIndex = (this.state.currentPage - 1) * this.state.pageSize;
    const endIndex = this.state.currentPage * this.state.pageSize;
    return this.state.dataUsing.slice(startIndex, endIndex).map((x) => {
      return (
        <Fragment key={`${x.executionId} ${x.procedureId}`}>
          {FunctionsStore.ChechDateForSpan(
            x.endedByUserId === undefined ? x.startedAt : x.endedAt,
            this.state.date
          )}
          <tr
            onClick={() => {
              this.naviGateToDetail(x);
            }}
            className={FunctionsStore.checkColor(x.statusId)}
          >
            <td className="noti-container">
              <div className="noti-counter">
                {this.state.comments.comments &&
                  this.state.comments.comments.filter(
                    (y) =>
                      y.executionId == x.executionId &&
                      y.procedureId == x.procedureId
                  ).length + x.media.length}
              </div>
              <div>
                <FontAwesomeIcon icon={faTasks} size="2x" />
              </div>
            </td>
            <td>{x.name}</td>
            <td>
              {FunctionsStore.formattedDate(
                x.endedByUserId === undefined ? x.startedAt : x.endedAt
              )}
            </td>
            <td>
              {this.state.users
                ? FunctionsStore.FindUserById(
                    x.endedByUserId === undefined
                      ? x.startedByUserId
                      : x.endedByUserId,
                    this.state.users
                  )[0]
                : ""}
            </td>
            <td>
              {x.steps.filter((s) => s.status == 2).length} /
              {x.revisions[0].steps.length}
            </td>
            <td>{FunctionsStore.checkActivationMode(x.statusId)}</td>
            <td>
              {x.externalSystems && x.externalSystems.length > 0
                ? x.externalSystems.length == 1
                  ? x.externalSystems.map(
                      (es) =>
                        FunctionsStore.externalSystemSwitch(es.systemId).name
                    )
                  : Array.from(
                      new Set(x.externalSystems.map((item) => item.systemId))
                    ).map(
                      (es) =>
                        FunctionsStore.externalSystemSwitch(es).name + ", "
                    )
                : "None"}
            </td>
          </tr>
        </Fragment>
      );
    });
  }
  search(
    startDate,
    endDate,
    optional = {
      text: this.state.searchTextValue,
      statusId: this.state.statusIdList,
      externalSystems: this.state.externalSystems,
    }
  ) {
    this.setState({ currentPage: 1 });
    FunctionsStore.date = "";
    if (this.state.userOrAll) {
      this.setState({
        dataUsing: this.filterData(
          startDate,
          endDate,
          optional.text == undefined
            ? this.state.searchTextValue
            : optional.text,
          optional.statusId == undefined
            ? this.state.statusIdList
            : optional.statusId,
          optional.externalSystems == undefined
            ? this.state.externalSystems
            : optional.externalSystems
        ).filter((x) =>
          this.state.currentUserProcedures.includes(x.executionId)
        ),
        searchTextValue:
          optional.text == undefined
            ? this.state.searchTextValue
            : optional.text,
        statusIdList:
          optional.statusId == undefined
            ? this.state.statusIdList
            : optional.statusId,

        externalSystems:
          optional.externalSystems == undefined
            ? this.state.externalSystems
            : optional.externalSystems,
      });
    } else {
      this.setState({
        dataUsing: this.filterData(
          startDate,
          endDate,
          optional.text == undefined
            ? this.state.searchTextValue
            : optional.text,
          optional.statusId == undefined
            ? this.state.statusIdList
            : optional.statusId,
          optional.externalSystems == undefined
            ? this.state.externalSystems
            : optional.externalSystems
        ),
        searchTextValue:
          optional.text == undefined
            ? this.state.searchTextValue
            : optional.text,
        statusIdList:
          optional.statusId == undefined
            ? this.state.statusIdList
            : optional.statusId,
        externalSystems:
          optional.externalSystems == undefined
            ? this.state.externalSystems
            : optional.externalSystems,
      });
    }
    if (this.state.userOrAll) {
      return this.filterData(
        startDate,
        endDate,
        optional.text == undefined ? this.state.searchTextValue : optional.text,
        optional.statusId == undefined
          ? this.state.statusIdList
          : optional.statusId,
        optional.externalSystems == undefined
          ? this.state.externalSystem
          : optional.externalSystems
      ).filter((x) => this.state.currentUserProcedures.includes(x.executionId));
    } else {
      return this.filterData(
        startDate,
        endDate,
        optional.text == undefined ? this.state.searchTextValue : optional.text,
        optional.statusId == undefined
          ? this.state.statusIdList
          : optional.statusId,
        optional.externalSystems == undefined
          ? this.state.externalSystem
          : optional.externalSystems
      );
    }
  }
  searchName(event) {
    FunctionsStore.date = "";
    FunctionsStore.filters.searchText = event.target.value;
    if (this.state.userOrAll) {
      this.setState({
        dataUsing: this.search(this.state.startDate, this.state.endDate, {
          text: event.target.value,
          statusId: this.state.statusId,
        }).filter((x) =>
          this.state.currentUserProcedures.includes(x.executionId)
        ),
      });
    } else {
      this.search(this.state.startDate, this.state.endDate, {
        text: event.target.value,
        statusId: this.state.statusId,
      });
    }
  }
  filterData(startDate, endDate, value, statusIds, externalSystemIds) {
    if (startDate == "") startDate = new Date(2000, 1, 1);
    if (endDate == "") endDate = new Date(2099, 1, 1);
    endDate = new Date(endDate).setDate(new Date(endDate).getDate() + 1);
    //for example, when a user click on 14th of April, time picker shows 2021-04-14 00:00:00
    //we need to add one day to consider 14th of April
    let res = this.state.data;
    if (statusIds)
      res = res.filter(
        (x) =>
          x.name &&
          x.name.toLowerCase().includes(value.toLowerCase()) &&
          new Date(x.sortDate) >= startDate &&
          new Date(x.sortDate) <= endDate &&
          statusIds.includes(x.statusId)
      );

    if (externalSystemIds) {
      res = res.filter(
        (x) =>
          x.name &&
          x.name.toLowerCase().includes(value.toLowerCase()) &&
          new Date(x.sortDate) >= startDate &&
          new Date(x.sortDate) <= endDate &&
          externalSystemIds != "" &&
          ((x.externalSystems &&
            x.externalSystems.some(
              (es) =>
                externalSystemIds &&
                externalSystemIds.includes(es.systemId + "")
            )) ||
            externalSystemIds.includes("0"))
      );
    } else {
      return [];
    }
    if (statusIds || externalSystemIds) {
      return res;
    }

    return this.state.data.filter(
      (x) =>
        x.name &&
        x.name.toLowerCase().includes(value.toLowerCase()) &&
        new Date(x.sortDate) >= startDate &&
        new Date(x.sortDate) <= endDate
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="d-flex justify-content-center d-flex align-items-center col col-sm-7 ml-1 mr-1 p-4">
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      );
    }
    return (
      <div className="padding-top-25px height-100vh">
        <div className="row form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            value={this.state.searchTextValue}
            className="form-control"
            placeholder="Søk prosedyrer"
            onChange={(e) => {
              this.searchName(e);
            }}
          />
        </div>

        <div style={{ padding: "10px" }}>
          <span className="badge "> Resultater: </span>
          <span className="badge badge-primary badge-pill ml-3">
            {this.state.dataUsing.filter((x) => x.statusId == 1).length} Aktive
          </span>

          <span className="badge badge-secondary badge-pill ml-3">
            {this.state.dataUsing.filter((x) => x.statusId == 2).length}{" "}
            Kansellert
          </span>
          <span className="badge badge-success badge-pill ml-3">
            {this.state.dataUsing.filter((x) => x.statusId == 3).length}{" "}
            Fullført
          </span>
        </div>
        <div>
          <table className="table table-curved">
            <thead className="table-thead table-header-font">
              <tr>
                <th>Aktivitet</th>
                <th>Prosedyrenavn</th>
                <th>Startet/Avsluttet</th>
                <th>Av</th>
                <th>Steg</th>
                <th>Status</th>
                <th>
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="no">
                <td colSpan="7">
                  {this.state.dataUsing.length ? (
                    <div className="row float-right">
                      <div
                        onClick={this.previousPage}
                        className="fa fa-angle-left mr-2 fa-2x font-weight-bold"
                      ></div>
                      <span className="paginationText">
                        {(this.state.currentPage - 1) * this.state.pageSize + 1}
                        -
                        {this.state.currentPage * this.state.pageSize >
                        this.state.dataUsing.length
                          ? this.state.dataUsing.length
                          : this.state.currentPage * this.state.pageSize}{" "}
                        of
                        {" " + this.state.dataUsing.length} items
                      </span>
                      <div
                        onClick={this.nextPage}
                        className="fa fa-angle-right ml-2 fa-2x font-weight-bold"
                      ></div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              {this.renderTableData()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default withRouter(SearchContent);
