import React, { Component } from "react";
import { Container, Row, Col, Navbar, NavItem, Nav } from "react-bootstrap";
import { Router, withRouter } from "react-router-dom";
import logo from "../images/logo.svg";
import "./Style.css";

class NavBarLogin extends Component {
  constructor(props) {
    super(props);
  }
  navToSupportPage() {
    this.props.history.push({
      pathname: "/support",
    });
  }
  render() {
    return (
      <Navbar fixed="top" className="bg-custom custom-nav" role="navigation">
        <Navbar.Brand href="/">
          {" "}
          <img src={logo} width="131" height="50" alt="Apply" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item>
              <Nav.Link href="/support" eventKey="/support">
                Support
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={this.props.onLogInClick}>
              <Nav.Link>Login</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default NavBarLogin;
